/*!
 * scripts.js
 *
 * Call any plugins and other regular JavaScript and jQuery code here.
 */

;(function($, window, document, undefined) {

    $(document).ready(function() {

        // var txt = $('#comments'),
        // var hiddenDiv = $(document.createElement('div'));
        // var content = null;

        $('.footer--global .nav__item--has-subnav > .nav__link').on('click', function(e){

            e.preventDefault();

            var parent = $(this).parent();
            var target = parent.find('.nav--sub');
            var link   = parent.find('.nav__link');
            var width  = (link.width() + 36) + 'px';

            target.css({'max-width' : width});
            parent.toggleClass('js-active');
            parent.find('.nav--sub').slideToggle();

        });

        $(window).on('resize', function(){

            form_labels();

            $('.gform_body textarea').each(function() {

                var el = $(this);
                el.css({
                    'height'  : '2.125rem',
                });
                textarea_resize(el);

            });

        });

        if ( $('.module-banners').length > 0 ) {

            window.banners = setTimeout(rotate_banners, 7000);

        }

        form_labels();

        textarea_autosize();

        $('.module-banners__preview').first().addClass('js-active');

        $('.module-banners__preview').on('click', activate_banner);

        if ( window.locations_js !== undefined ) {

            if ( window.locations_js.ajax_url !== undefined ) {

                loadScript(window.locations_js.ajax_url, function(){

                    var is_draggable = window.Modernizr.touch;

                    if ( is_draggable ) {

                        $('.map-wrapper').append('<div class="btn btn--draggable-toggle"><svg class="icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-drag"></use></svg> click to drag</div>');

                        $('.btn--draggable-toggle').on('click', function(){

                            window.map.draggable = (window.map.draggable) ? false : true;
                            $(this).toggleClass('js-active');

                        });
                    }

                });

            }
        }

        $('.nav--global a').on('touchstart, click', touch_subnav);

    });

})(jQuery, window, document);


function rotate_banners() {

    var current = $('.module-banners__full .js-active');
    var id  = current.attr('id');
    var num = id.substr(7);

    var _current = $('#preview-' + num);

    var count = $('.module-banners__banner').length;

    var new_num = parseInt(num) + 1;
    if ( new_num > count ) {
        new_num = 1;
    }

    var next  = $('#banner-' + new_num);
    var _next = $('#preview-' + new_num);

    next.addClass('js-active');
    _next.addClass('js-active');

    current.removeClass('js-active');
    _current.removeClass('js-active');

    window.banners = setTimeout(rotate_banners, 7000);

}

function activate_banner() {

    var self = $(this);
    var id = self.attr('id');
    var num = id.substr(8);

    $('.module-banners__full .js-active').removeClass('js-active');
    $('.module-banners__previews .js-active').removeClass('js-active');

    var next  = $('#banner-' + num);
    var _next = $('#preview-' + num);

    next.addClass('js-active');
    _next.addClass('js-active');

    clearTimeout(window.banners);
    window.banners = setTimeout(rotate_banners, 7000);

}


function touch_subnav(e) {

    var parent = $(this).parent();

    if ( parent.hasClass('nav__item--has-subnav') && ! parent.hasClass('js-hover') && window.matchMedia('(min-width: 45em)').matches ) {

        e.preventDefault();
        e.stopPropagation();

        $('.js-hover').removeClass('js-hover');
        $(this).parent().addClass('js-hover');

    }

}


function form_labels() {

    // $('.gform_body label').each(function($i){

    //     var width = $(this).width() + 25;
    //     var id    = '#' + $(this).attr('for');

    //     var target = $(id);
    //     var type = target.attr('type');
    //     var tag  = target.get(0).tagName;
    //     tag = tag.toLowerCase();

    //     if ( type === 'text' || type === 'email' || type === 'number' || type === 'tel' || tag === 'select') {

    //         $(id).css('padding-left', width + 'px');

    //     }

    //     $(this).css('opacity', 1);

    // });

    $('.gform_body input, .gform_body textarea').on('focus', function(){

        $(this).closest('.gfield').addClass('js-active');

    });

    $('.gform_body input, .gform_body textarea, .gform_body select').on('blur', function(){

        if ($(this).val().length === 0){
            $(this).closest('.gfield').removeClass('js-active');
        }

    });

    $('.gform_body select').on('click', function(){

        $(this).closest('.gfield').addClass('js-active');

    });
    $('.gform_body select').on('change input', function(){

        if ($(this).val().length === 0){
            $(this).closest('.gfield').removeClass('js-active');
        }

    });


}

function textarea_autosize() {

    $('.gform_body textarea').on('paste keydown change', function() {

        var el = $(this);

        setTimeout(function(){

            textarea_resize(el);

        },0);

    });

}


function textarea_resize(el) {

    // el.style.cssText = 'height:auto; padding:0';
    // for box-sizing other than "content-box" use:
    // el.style.cssText = '-moz-box-sizing:content-box';

    el.css({

        'height' : el.prop('scrollHeight') + 'px'

    });

}


function loadScript(src, callback){

    var script = document.createElement('script');
    script.type = 'text/javascript';

    if(callback) {

        script.onload=callback;

    }

    document.getElementsByTagName('head')[0].appendChild(script);
    script.src = src;

}

