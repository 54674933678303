/*!
 * jQuery.sample.js
 *
 * A sample jQuery plugin factory wrapper.
 */

;(function ($, window, document, undefined) {

    // Create the defaults once
    var pluginName = 'defaultPluginName',
        defaults = {
            propertyName: 'value'
        };

    // The actual plugin constructor
    function Plugin(element, options) {

        this.element = element;
        this.options = $.extend({}, defaults, options) ;

        this._defaults = defaults;
        this._name = pluginName;

        this.init();

    }

    Plugin.prototype.init = function () {
        // Place initialization logic here
        // We already have access to the DOM element and
        // the options via the instance, e.g. this.element
        // and this.options
    };

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'plugin_' + pluginName)) {
                $.data(this, 'plugin_' + pluginName,
                new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);
