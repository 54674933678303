/**
 * tools_dist.js
 *
 * A collection of tools and shivs that should be included in production builds.
 */



/**
 * Modernizr additional tests for iOS devices.
 */

/* global Modernizr:true */
if (window.Modernizr) {

    Modernizr.addTest('ipad', function () {
        return !!navigator.userAgent.match(/iPad/i);
    });
     
    Modernizr.addTest('iphone', function () {
        return !!navigator.userAgent.match(/iPhone/i);
    });
     
    Modernizr.addTest('ipod', function () {
        return !!navigator.userAgent.match(/iPod/i);
    });
     
    Modernizr.addTest('appleios', function () {
        return (Modernizr.ipad || Modernizr.ipod || Modernizr.iphone);
    });

}



/**
 * requestAnimationFrame polyfill.
 */
if (!Date.now) {

    Date.now = function() {

        return new Date().getTime();
        
    };

}

(function() {

    'use strict';
    var vendors = ['webkit', 'moz'];

    for (var i = 0; i < vendors.length && !window.requestAnimationFrame; ++i) {

        var vp = vendors[i];

        window.requestAnimationFrame = window[vp + 'RequestAnimationFrame'];
        window.cancelAnimationFrame = (window[vp + 'CancelAnimationFrame'] ||

        window[vp + 'CancelRequestAnimationFrame']);

    }

    if (/iP(ad|hone|od).*OS 6/.test(window.navigator.userAgent) ||
        !window.requestAnimationFrame || !window.cancelAnimationFrame) {

        var lastTime = 0;

        window.requestAnimationFrame = function(callback) {

            var now = Date.now();
            var nextTime = Math.max(lastTime + 16, now);

            return setTimeout(function() { callback(lastTime = nextTime); },
                              nextTime - now);

        };

        window.cancelAnimationFrame = clearTimeout;

    }

}());
